import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  imports: [TranslateModule],
  standalone: true
})
export class CountdownComponent implements OnInit {
  TIME_LIMIT = 45;
  FULL_DASH_ARRAY = 283;
  timeLeft: number;
  circleDasharray = '283';

  constructor() {}

  ngOnInit(): void {
    this.timeLeft = this.TIME_LIMIT;
    this.startTimer();
  }

  startTimer(): void {
    let timerInterval = null;
    let timePassed = 0;
    timerInterval = setInterval(() => {
      if (this.timeLeft > 0) {
        timePassed = timePassed += 1;
        this.timeLeft = this.TIME_LIMIT - timePassed;
        this.setCircleDasharray();
      } else {
        clearInterval(timerInterval);
      }
    }, 1000);
  }

  calculateTimeFraction(): number {
    const rawTimeFraction = this.timeLeft / this.TIME_LIMIT;
    return rawTimeFraction - (1 / this.TIME_LIMIT) * (1 - rawTimeFraction);
  }

  setCircleDasharray(): void {
    this.circleDasharray = `${(
      this.calculateTimeFraction() * this.FULL_DASH_ARRAY
    ).toFixed(0)} 283`;
  }
}
