<h2>{{ 'REGISTER.GREETING' | translate }}</h2>
<p>{{ 'REGISTER.EXPLANATION' | translate }}</p>

<form [formGroup]="candidateRegisterForm">
  <div class="form-container">
    <div class="profile-image-container">
      <div class="profile-image">
        <div class="profile-image-window"
            [ngClass]="{'is-invalid': picture.invalid && picture.touched}">
          <img alt="User profile picture"
                *ngIf="picture.value"
                [src]="picture.value" />
        </div>
        <button *ngIf="picture.value"
                class="btn btn-reset-image-icon"
                (click)="resetImage()">
          <span class="icon-remove icon"></span>
        </button>
        <button *ngIf="!picture.value"
              class="btn btn-icon-upload"
              (click)="imageUpload.click($event)">
          <span class="icon-upload icon"></span>
        </button>
      </div>
      <div class="form-group">
        <input #imageUpload
                type="file"
                (change)="uploadImage($event.target)"
                hidden
                class="form-control"
                [ngClass]="{'is-invalid': picture.invalid && picture.touched}" />
        <div class="invalid-feedback" *ngIf="picture.invalid && picture.touched">
          <div *ngIf="picture.errors?.required">
            {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-input-wrapper col-12 col-xl-6">
        <input type="text"
               class="form-control"
               formControlName="name"
               placeholder="{{ 'REGISTER.LABELS.NAME' | translate }}">
        <div class="invalid-feedback" *ngIf="name.invalid && name.touched">
          <div *ngIf="name.errors?.required">
            {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group form-input-wrapper col-12 col-xl-6">
        <input class="form-control"
               formControlName="email"
               placeholder="{{ 'REGISTER.LABELS.EMAIL' | translate }}">
        <div class="invalid-feedback" *ngIf="email.invalid && email.touched">
          <div *ngIf="email.errors?.required">
            {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
          </div>
          <div *ngIf="email.errors?.pattern">
            {{ 'SHARED.EMAIL_INVALID_ERROR_MSG' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group mobile-phone-group col-12 col-xl-6">
        <select formControlName="networks"
                class="form-control custom-select col-3"
                (change)="changedNetwork()">
          <option class="form-control"
                  *ngFor="let network of countryCallingCodes"
                  [ngValue]="network">
            {{ network.callingCode }}
          </option>
        </select>
        <div class="phone-input col-9">
          <input type="text"
                 class="form-control"
                 formControlName="phone"
                 placeholder="{{ 'REGISTER.LABELS.PHONE' | translate }}">
          <div class="invalid-feedback" *ngIf="phone.invalid && phone.touched">
            <div *ngIf="phone.errors?.required">
              {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
            </div>
            <div *ngIf="phone.errors?.pattern">
              {{ 'SHARED.PHONE_ERROR_PATTERN_MSG' | translate }}
            </div>
            <div *ngIf="!phone.errors?.pattern && (phone.errors?.minlength || phone.errors?.maxlength)">
              <ng-container *ngIf="(phoneNumberMinLength !== 0) && (phoneNumberMinLength < phoneNumberMaxLength)">
                {{ 'SHARED.PHONE_ERROR_LENGTH_MSG' | translate: {minLength: phoneNumberMinLength, maxLength: phoneNumberMaxLength}  }}
              </ng-container>
              <ng-container *ngIf="phoneNumberMinLength === 0">
                {{ 'SHARED.PHONE_ERROR_LENGTH_MSG_MAX_LENGTH' | translate: {maxLength: phoneNumberMaxLength}  }}
              </ng-container>
              <ng-container *ngIf="phoneNumberMinLength === phoneNumberMaxLength">
                {{ 'SHARED.PHONE_ERROR_LENGTH_MSG_FIXED_LENGTH' | translate: {length: phoneNumberMaxLength}  }}
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group form-input-wrapper col-12 col-xl-6">
        <select formControlName="location"
                class="form-control custom-select"
                [ngClass]="{'default-option': !location.value}">
          <option [value]="null" [selected]="true" [disabled]="true">
            {{ 'SHARED.LOCATION' | translate }}
          </option>

          <option class="form-control"
                  *ngFor="let location of locations"
                  [ngValue]="location.id">
            {{ location.name[language] }}
          </option>
        </select>
        <div class="invalid-feedback" *ngIf="location.invalid && location.touched">
          <div *ngIf="location.errors?.required">
            {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
          </div>
        </div>
      </div>
      <div class="radio-container">
        <h3 class="radio-title">{{ 'REGISTER.LABELS.GENDER' | translate}}</h3>
        <ul class="items-list">
          <li *ngFor="let gender of genders"
              class="item">
            <label class="custom-radio-btn">
              <i class="label">{{ gender.name | translate }}</i>
              <input
                     formControlName="gender"
                     type="radio"
                     [value]="gender.value">
              <i class="checkmark"></i>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <ng-container *ngIf="country">
    <div class="form-group form-input-wrapper">
      <select formControlName="country"
              class="form-control custom-select"
              (change)="changedCountry()"
              [ngClass]="{'default-option': !country.value?.id}">
        <option [value]="null" [selected]="true" [disabled]="true">
          {{ 'REGISTER.PICK_COUNTRY_YOU_SHOW_INTEREST_FOR' | translate }}
        </option>

        <option class="form-control"
                *ngFor="let country of countries"
                [ngValue]="country">
          {{ country.name }}
        </option>
      </select>
      <div class="invalid-feedback" *ngIf="country.invalid && country.touched">
        <div *ngIf="email.errors?.required">
          {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
        </div>
      </div>
    </div>

    <div class="form-group form-input-wrapper" *ngIf="country?.value?.id">
      <select formControlName="company"
              class="form-control custom-select"
              (change)="changedCompany()"
              [ngClass]="{'default-option': !company.value?.id}">
        <option [value]="null" [selected]="true" [disabled]="true">
          {{ 'REGISTER.PICK_COMPANY_YOU_SHOW_INTEREST_FOR' | translate }}
        </option>

        <option class="form-control"
                *ngFor="let company of countryRelatedCompaniesWithUniversalJob"
                [ngValue]="company">
          {{ company.name }}
        </option>
      </select>
      <div class="invalid-feedback" *ngIf="company.invalid && company.touched">
        <div *ngIf="email.errors?.required">
          {{ 'SHARED.REQUIRED_ERROR_MESSAGES' | translate }}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="form-group tos">
    <label class="custom-checkbox">
      <input formControlName="acceptTermsAndConditions"
             type="checkbox" />
      {{ "REGISTER.AGREE_TO_HIGHER" | translate }}
      <span class="checkmark"></span>
    </label>
    <a (click)="openTermsAndConditionsModal()">
      {{ 'REGISTER.TOS' | translate }}
    </a>
  </div>

  <div class="btn-group">
    <button type="submit"
            class="btn-secondary"
            (click)="onSubmit()"
            [disabled]="candidateRegisterForm.invalid || disableButton">
      {{ 'BUTTONS.START' | translate }}
    </button>
  </div>
</form>
