import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CandidateRegisterFormComponent } from './candidate-register-form/candidate-register-form.component';
import { RegisterRegularJobComponent } from './register-regular-job/register-regular-job.component';
import { RegisterUniversalJobComponent } from './register-universal-job/register-universal-job.component';

@NgModule({
  declarations: [
    CandidateRegisterFormComponent,
    RegisterRegularJobComponent,
    RegisterUniversalJobComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})
export class CandidateRegisterModule { }
