import { LangData } from 'src/app/models/lang-data.model';
import { LANGUAGES } from 'src/app/resources/languages';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { checkForQueryParams } from 'src/app/shared-functions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationKeys } from '../../../../models/translation-keys.model';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit, OnDestroy {

  activeLanguage: string;
  nativeLanguage: LangData;
  isEnterprise: boolean;
  isAllJobs: boolean;
  possibleLanguages: LangData[];

  readonly availableLanguages = LANGUAGES;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private configService: AppConfigService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activeLanguage = this.translateService.currentLang;
    this.trackLanguageChanges();

    const { isEnterprise, isAllJobs, language, possibleLanguages } = this.configService.organization;

    this.isEnterprise = isEnterprise;
    this.isAllJobs = isAllJobs;
    this.nativeLanguage = LANGUAGES.find(lang => lang.iso6391 === language);
    this.possibleLanguages = [];

    if (this.isEnterprise || this.isAllJobs) {
      possibleLanguages
        .forEach((lang: TranslationKeys) => {
          const langData = LANGUAGES.find(lng => lng.iso6391 === lang);

          if (langData) {
            this.possibleLanguages.push(langData);
          }
        });
      this.sortLanguages();
    }
  }

  trackLanguageChanges(): void {
    this.translateService
      .onLangChange
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(({lang}: LangChangeEvent) => this.activeLanguage = lang);
  }

  sortLanguages(): void {
    this.possibleLanguages.sort((a: LangData, b: LangData) => {
      if (a.name[this.activeLanguage] < b.name[this.activeLanguage]) {
        return -1;
      }
      if (a.name[this.activeLanguage] > b.name[this.activeLanguage]) {
        return 1;
      }
      return 0;
    });
  }

  changeLanguage(langCode: string): void {
    const oldActiveLang = this.translateService.currentLang;
    const url = this.router.url.replace(`/${oldActiveLang}`, `/${langCode}`);
    this.activeLanguage = langCode;
    if (this.isEnterprise) {
      this.sortLanguages();
    }

    const {urlWithoutParams, queryParams} = checkForQueryParams(url);

    this.translateService.use(langCode)
      .subscribe(() => {
        this.router.navigate([urlWithoutParams], { queryParams, relativeTo: this.route});
      });
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
