import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CreateCandidateResponse, PicturePayload } from '../../models/candidate.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../services/loader.service';
import { CandidateRegisterService } from '../../services/candidate-register.service';
import { catchError, mergeMap, Observable } from 'rxjs';
import { IApplication } from '../../models/application.model';
import { Application } from '../../classes/application.class';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';

export abstract class AbstractCandidateRegister {

  candidateRegisterForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    email: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[.][A-Za-z]{2,4}$'
        ),
      ],
    ],
    networks: [null, [Validators.required]],
    phone: [
      '',
      [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ],
    ],
    acceptTermsAndConditions: ['', [Validators.requiredTrue]],
    picture: ['', [Validators.required]],
    location: [null, [Validators.required]],
    gender: ['', [Validators.required]]
  });

  companyGuid: string;
  termsAndConditions: string;
  candidateData: CreateCandidateResponse;

  protected constructor(
    protected fb: FormBuilder,
    protected router: Router,
    protected toastr: ToastrService,
    protected loaderService: LoaderService,
    protected translateService: TranslateService,
    protected candidateRegisterService: CandidateRegisterService,
    protected errorHandlingService: ErrorHandlingService
  ) { }

  createCandidate(picturePayload: PicturePayload): void {
    this.loaderService.show();

    const { value } = this.candidateRegisterForm;

    const payload = {
      email: value.email.toLowerCase(),
      gender: value.gender,
      location: value.location,
      company:  this.companyGuid,
      picture: picturePayload
    }

    this.candidateRegisterService
      .createCandidate(payload)
      .pipe(
        mergeMap((createCandidateResponse: CreateCandidateResponse) => {
          this.candidateData = createCandidateResponse;
          return this.createApplication();
        }),
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        )
      )
      .subscribe(() => {
        this.loaderService.hide();
      });
  }

  abstract createApplication(): Observable<IApplication | Application>;

  alreadyAppliedForJob(): void {
    sessionStorage.removeItem('jobId');
    sessionStorage.removeItem('companyId');
    sessionStorage.removeItem('companyDomain');
    sessionStorage.removeItem('jobCompanyLogo');
    this.router.navigate(['/']);
    this.toastr.error(
      this.translateService.instant('ERRORS.ALREADY_FINISHED_APP')
    );
  }
}
