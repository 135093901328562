<div class="container notranslate"
     [ngClass]="{
       'company': activePage === 'company',
       'quiz': activePage === 'quiz',
       'application': activePage === 'application',
       'application-complete': activePage === 'application-complete'
      }">

  <app-header></app-header>

  <main [@routeAnimations]="prepareOutlet(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
  </main>

  <div *ngIf="showSendLinkContainer"
       class="send-link-container">
    <button class="btn-link"
            (click)="showHideSendLinkModal()">{{ 'SEND_LINK.SEND_LINK_BTN' | translate }}</button>
  </div>

</div>

<app-modal-container></app-modal-container>

<app-loader></app-loader>
