import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConfigService } from 'src/app/services/app-config.service';
import { Country, EnterpriseCompany } from 'src/app/models/company.model';
import { ConfirmationModalData } from 'src/app/models/modal.interface';
import { ConfirmationModal } from 'src/app/classes/modal.class';
import { CandidateRegisterService } from 'src/app/services/candidate-register.service';
import { CountryCallingCode } from 'src/app/models/country-calling-codes.model';
import { ModalService } from 'src/app/services/modal.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ALLOWED_IMAGE_FORMATS, IMAGE_FILE_SIZE_LIMIT } from 'src/app/resources/allowed-file-formats';
import { Gender } from 'src/app/models/gender.model';
import { forkJoin } from 'rxjs';
import { PicturePayload } from 'src/app/models/candidate.model';
import { Organization } from 'src/app/classes/organization.class';

@Component({
  selector: 'app-candidate-register-form',
  templateUrl: './candidate-register-form.component.html',
  styleUrls: ['./candidate-register-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateRegisterFormComponent implements OnInit {

  @HostBinding('class.route-card') card = true;

  @Input() candidateRegisterForm: FormGroup;
  @Input() termsAndConditions: string;
  // Universal job related input properties
  @Input() countryRelatedCompaniesWithUniversalJob?: EnterpriseCompany[] = [];
  @Input() countries?: Country[] = [];
  @Input() countryId?: number;

  @Output() formSubmitted: EventEmitter<PicturePayload> = new EventEmitter<PicturePayload>();
  @Output() countryChanged?: EventEmitter<void> = new EventEmitter<void>();
  @Output() companyChanged?: EventEmitter<void> = new EventEmitter<void>();

  countryCallingCodes: CountryCallingCode[];
  companyCountryCallingCodes: CountryCallingCode;

  phoneNumberMinLength: number;
  phoneNumberMaxLength: number;
  disableButton = false;
  @ViewChild('imageUpload') imageUpload: ElementRef;
  picturePayload: PicturePayload;
  locations: Location[];
  language: string;
  genders: Gender[] = [
    {
      name: 'REGISTER.LABELS.MALE',
      value: 'male'
    },
    {
      name: 'REGISTER.LABELS.FEMALE',
      value: 'female'
    },
    {
      name: 'REGISTER.LABELS.NON-BINARY',
      value: 'non-binary'
    }
  ]

  constructor(
    private configService: AppConfigService,
    private candidateService: CandidateRegisterService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) { }

  get name(): FormControl {
    return this.candidateRegisterForm.get('name') as FormControl;
  }

  get email(): FormControl {
    return this.candidateRegisterForm.get('email') as FormControl;
  }

  get phone(): FormControl {
    return this.candidateRegisterForm.get('phone') as FormControl;
  }

  get networks(): FormControl {
    return this.candidateRegisterForm.get('networks') as FormControl;
  }

  get company(): FormControl {
    return this.candidateRegisterForm.get('company') as FormControl;
  }

  get country(): FormControl {
    return this.candidateRegisterForm.get('country') as FormControl;
  }

  get acceptTermsAndConditions(): FormControl {
    return this.candidateRegisterForm.get('acceptTermsAndConditions') as FormControl;
  }

  get picture(): FormControl {
    return this.candidateRegisterForm.get('picture') as FormControl;
  }

  get location(): FormControl {
    return this.candidateRegisterForm.get('location') as FormControl;
  }

  get gender(): FormControl {
    return this.candidateRegisterForm.get('gender') as FormControl;
  }

  ngOnInit(): void {
    this.initializeFormData();
  }

  onSubmit(): void {
    this.disableButton = true;
    this.formSubmitted.emit(this.picturePayload);
  }

  initializeFormData() {
    forkJoin([
      this.candidateService.getCountryCallingCodes(),
      this.candidateService.getLocations(this.countryId)
    ]).subscribe(([countryCallingCodes, locations]) => {
      this.countryCallingCodes = countryCallingCodes;

      const { organization } = this.configService.config;

      this.companyCountryCallingCodes = this.countryCallingCodes
        .find(({language}: CountryCallingCode) => language === organization.language);

      this.candidateRegisterForm.patchValue({networks: this.companyCountryCallingCodes});
      this.changedNetwork();

      this.setLanguage(organization);
      this.locations = locations;
      this.cdr.detectChanges();

    })
  }

  setLanguage(organization: Organization): void {
    if (organization.isEnterprise) {
      const companyId = +sessionStorage.getItem('companyId');
      const currentCompany = organization.companies
        .find((company: EnterpriseCompany) => company.id === companyId);

      this.language = currentCompany.language;
    } else {
      this.language = organization.language;
    }
  }

  changedNetwork(): void {
    this.phoneNumberMaxLength = this.networks.value.maxLength;
    this.phoneNumberMinLength = this.networks.value.minLength;

    this.phone.setValidators([
      Validators.minLength(this.phoneNumberMinLength),
      Validators.maxLength(this.phoneNumberMaxLength),
      Validators.required,
      Validators.pattern('^[1-9][0-9]*$')
    ]);

    this.phone.updateValueAndValidity();
  }

  changedCountry(): void {
    this.countryChanged.emit();
  }

  changedCompany(): void {
    this.companyChanged.emit();
  }

  openTermsAndConditionsModal(): void {
    if (this.acceptTermsAndConditions.disabled) {
      return;
    }

    const data: ConfirmationModalData = {
      title: 'REGISTER.TOS',
      htmlContent: this.termsAndConditions,
      confirmBtnTitle: 'BUTTONS.AGREE',
      hideCancelButton: true,
      confirm: () => this.iAgree(),
    };

    this.modalService.addModal(new ConfirmationModal(data));
  }

  iAgree(): void {
    this.acceptTermsAndConditions.setValue(true);
    this.cdr.detectChanges();
  }

  resetImage(): void {
    this.picture.reset();
    this.picture.markAsTouched();
    this.imageUpload.nativeElement.value = '';
  }

  uploadImage({files}: HTMLInputElement): Promise<void> {

    if (files.length === 0) {
      return;
    }

    if (files.length === 1 && files[0].size > IMAGE_FILE_SIZE_LIMIT) {
      this.toastr.error(
          this.translateService.instant(
            'SHARED.FILE_SIZE_ERR_MSG', { fileName: files[0].name }
          )
      );
      return;
    }

    if (files.length === 1) {
      this.loaderService.show();
      try {
        this.readUploadedFileAsURL(files[0]);
      } catch (error) {
        this.loaderService.hide();
      }
    } else {
      this.toastr.error(
        this.translateService.instant(
          'SHARED.UPLOAD_MORE_THAN_ONE_FILE_NOT_ALLOWED'
        )
      );
    }
  }

  readUploadedFileAsURL: (file: File) => Promise<string> = (file: File) => {
    if (ALLOWED_IMAGE_FORMATS.includes(file.type)) {
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.readAsDataURL(file);

        reader.onload = () => {
          const result = reader.result.toString();
          this.picture.setValue(result);
          this.picturePayload = {
              fileName: file.name,
              context: 'candidate',
              base64: result.split(',')[1]
          }
          this.cdr.detectChanges();
          resolve(result);
          this.loaderService.hide();
        };

        reader.onerror = () => {
          reader.abort();
          this.toastr.error(this.translateService.instant('SHARED.UPLOAD_IMG_ERR_MSG'));
          reject();
          this.loaderService.hide();
        };
      });
    } else {
      this.toastr.error(this.translateService.instant('SHARED.UPLOAD_FILE_TYPE_NOT_ALLOWED'));
      this.loaderService.hide();
      return Promise.reject();
    }
  };

  enableButton(): void {
    this.disableButton = false;
    this.cdr.detectChanges();
  }
}
