import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { Observable, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  progress: Observable<number>;
  private _ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private progressBarService: ProgressBarService
  ) { }

  ngOnInit() {
    this.progress = this.progressBarService.progress.pipe(
      takeUntil(this._ngUnsubscribe)
    );
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }

}
