import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LayoutModule } from './modules/layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { createTranslateLoader } from './ngx-translation-loader';
import { QuizModule } from './modules/quiz/quiz.module';
import { AppConfigService } from './services/app-config.service';
import { initializeApplication } from './initialize-application';
import { ToastrModule } from 'ngx-toastr';
import { ApplicationStartComponent } from './application-start/application-start.component';
import { ApplicationCompleteComponent } from './application-complete/application-complete.component';
import { SetupService } from './services/setup.service';
import { SharedModule } from './modules/shared/shared.module';
import { CountdownComponent } from './countdown/countdown.component';
import { ModalModule } from './modules/modal/modal.module';
import { CandidateRegisterModule } from './modules/candidate-register/candidate-register.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CountdownComponent,
    ApplicationStartComponent,
    ApplicationCompleteComponent,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      preventDuplicates: true,
      easeTime: 1000,
      progressBar: true,
      extendedTimeOut: 3000
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    LayoutModule,
    QuizModule,
    ModalModule,
    CandidateRegisterModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      deps: [AppConfigService],
      multi: true,
    },
    SetupService,
    AppConfigService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
