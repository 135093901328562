import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CandidateRegisterService } from 'src/app/services/candidate-register.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { TranslateService } from '@ngx-translate/core';
import { Organization } from 'src/app/classes/organization.class';
import { Country, EnterpriseCompany } from 'src/app/models/company.model';
import { REFERER_TYPES } from 'src/app/resources/referer-types';
import { RegisterCandidatePayload } from 'src/app/models/candidate.model';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Application } from 'src/app/classes/application.class';
import { AbstractCandidateRegister } from '../abstract-candidate-register.class';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-register-universal-job',
  templateUrl: './register-universal-job.component.html',
  styleUrls: ['./register-universal-job.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterUniversalJobComponent extends AbstractCandidateRegister implements OnInit {

  companies: EnterpriseCompany[] = [];
  countryRelatedCompaniesWithUniversalJob: EnterpriseCompany[] = [];
  countries: Country[] = [];
  universalJobId: number;

  constructor(
    fb: FormBuilder,
    router: Router,
    toastr: ToastrService,
    translateService: TranslateService,
    loaderService: LoaderService,
    candidateRegisterService: CandidateRegisterService,
    errorHandlingService: ErrorHandlingService,
    private configService: AppConfigService,
    ) {
    super(fb, router, toastr, loaderService, translateService, candidateRegisterService, errorHandlingService);
  }

  get country(): FormControl {
    return this.candidateRegisterForm.get('country') as FormControl;
  }

  get company(): FormControl {
    return this.candidateRegisterForm.get('company') as FormControl;
  }

  get acceptTermsAndConditions(): FormControl {
    return this.candidateRegisterForm.get('acceptTermsAndConditions') as FormControl;
  }

  ngOnInit(): void {
    this.initializeUniversalJobRegister();
  }

  initializeUniversalJobRegister(): void {
    const { organization } = this.configService.config;

    this.setReferrer(organization);

    if (!organization.isEnterprise) {
      this.universalJobId = organization.universalJobs[0].id;
      this.termsAndConditions = organization.termsAndConditions;
      return;
    }

    this.acceptTermsAndConditions.disable();
    this.candidateRegisterForm.addControl('company', this.fb.control(null, [ Validators.required ]));
    this.candidateRegisterForm.addControl('country', this.fb.control(null, [ Validators.required ]));

    this.setUniversalJobCompaniesAndCountries(organization);
  }

  setUniversalJobCompaniesAndCountries(organization: Organization): void {
    this.companies = organization.companies;
    const countries = [];

    this.companies
      .forEach((company: EnterpriseCompany) => {
        if (company.active && company.useUniversalJob) {
          const { country } = company.location;

          if (!countries.find(({id}: Country) => id === country.id)) {
            countries.push(country);
          }
        }
      });

    this.countries = countries;
  }

  setReferrer(organization: Organization): void {
    if (!organization.isEnterprise && this.configService.referrer) {
      this.candidateRegisterService
        .submitReferer(REFERER_TYPES.UNIVERSAL_JOB, this.configService.referrer, organization.universalJobs[0].guid)
        .subscribe();
    }
  }

  createApplication(): Observable<Application> {
    const { phone, email, networks, name } = this.candidateRegisterForm.value;

    const payload: RegisterCandidatePayload = {
      candidate: this.candidateData,
      phone: networks.callingCode + phone,
      email,
      name,
      universalJobId: this.universalJobId
    };

    const { isEnterprise } = this.configService.organization;

    if (isEnterprise && !sessionStorage.getItem('companyId')) {
      sessionStorage.setItem('companyId', this.company.value.id.toString());
    }

    return this.candidateRegisterService
      .registerApplication(payload)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        ),
        tap((application: Application) => {
          const {jobApplication, jobInfo} = application;

          this.configService.config.jobApplication = jobApplication;
          this.configService.config.job = jobInfo;

          if (jobApplication.applicationComplete) {
            this.alreadyAppliedForJob();
            return;
          }

          const route = jobApplication.continueApplication
            ? 'quiz'
            : 'application-start';

          this.router.navigate(
            [route],
            { queryParams: {application: jobApplication.guid}}
          );
        })
      );
  }

  countryChanged(): void {
    this.termsAndConditions = '';
    this.company.reset();
    this.acceptTermsAndConditions.reset();
    this.acceptTermsAndConditions.disable();

    const countryRelatedCompaniesWithUniversalJob = [];

    this.companies
      .forEach((company: EnterpriseCompany) => {
        if (company.active && company.location.country.id === this.country.value.id && company.useUniversalJob) {
          countryRelatedCompaniesWithUniversalJob.push(company);
        }
      });

    this.countryRelatedCompaniesWithUniversalJob = countryRelatedCompaniesWithUniversalJob;
  }

  companyChanged(): void {
    const company: EnterpriseCompany = this.company.value;

    this.universalJobId = company.universalJob.id;
    this.termsAndConditions = company.termsAndConditions;
    sessionStorage.setItem('companyId', company.id.toString());

    this.acceptTermsAndConditions.enable();
  }
}
