import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplicationStartComponent } from './application-start/application-start.component';
import { ApplicationCompleteComponent } from './application-complete/application-complete.component';
import { ExpressInterestGuard } from './guards/express-interest.guard';
import { RegisterRegularJobComponent } from './modules/candidate-register/register-regular-job/register-regular-job.component';
import { RegisterUniversalJobComponent } from './modules/candidate-register/register-universal-job/register-universal-job.component';

export const routes: Routes = [
  {
    path: ':lang',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule),
      },
      {
        path: 'quiz',
        loadChildren: () => import('./modules/quiz/quiz.module').then(m => m.QuizModule)
      },
      {
        path: 'video',
        loadChildren: () => import('./modules/video-questions/video-questions.module').then(m => m.VideoQuestionsModule)
      },
      {
        path: 'application-update',
        loadChildren: () => import('./application-update/application-update.module').then(m => m.ApplicationUpdateModule)
      },
      {
        path: 'application-complete',
        loadComponent: () => import('./application-complete/application-complete.component').then((m) => m.ApplicationCompleteComponent),
      },
      {
        path: 'application-start',
        loadComponent: () => import('./application-start/application-start.component').then((m) => m.ApplicationStartComponent),
      },
      {
        component: RegisterRegularJobComponent,
        path: 'register'
      },
      {
        component: RegisterUniversalJobComponent,
        path: 'register-interest',
        canActivate: [ExpressInterestGuard]
      },
      {
        path: '**',
        redirectTo: 'jobs'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
