import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../services/app-config.service';

export function ExpressInterestGuard(): boolean {

  const router = inject(Router);
  const configService = inject(AppConfigService);

  const company = configService.organization;
  const universalJob = company.useUniversalJob;
  if (universalJob) {
    return true;
  }

  router.navigate(['/jobs']);
  return false;
}
